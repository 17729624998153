const Validation = yup => ({
    user_id: yup.mixed().required('Campo obrigatório'),
    report_card_id: yup.number()
        .required('Campo obrigatório'),
    name: yup.string()
        .required('Campo obrigatório'),
    roles: yup.string(),
    // page_navigation: yup.boolean()
    //     .required('Campo obrigatório'),
})

export default Validation